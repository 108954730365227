import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormHandleSubmit, UseFormSetValue } from 'react-hook-form';
import { Flex } from '../flex';
import { blobUrlToFile, ShareImageComponent } from './ShareImage';
import { useFileUploader } from '@/hooks';
import { notify } from '@/utils';

interface IFormInput {
	title: string;
	description: string;
	displayImage: string;
}

interface IShareFormProps {
	initialImage: string;
	onSubmit: (formData: {
		title: string;
		description: string;
		imageUrl?: string;
	}) => Promise<void>;
	handleSubmit: UseFormHandleSubmit<IFormInput, undefined>;
	errors: FieldErrors<IFormInput>;
	isValid: boolean;
	control: Control<IFormInput, any>;
	setValue: UseFormSetValue<IFormInput>;
}

export const ShareForm: FC<IShareFormProps> = ({
	onSubmit, initialImage,
	control, errors,
	handleSubmit, isValid,
	setValue
}) => {
	const [linkImage, setLinkImage] = useState<string>(initialImage);

	// Sync the form's image field with the displayed image
	useEffect(() => {
		setValue('displayImage', linkImage);
		console.log('link image', linkImage);
		// eslint-disable-next-line
	}, [linkImage, setValue]);

	const { addFile, loading: loadingMedia } = useFileUploader();

	const handleSave = async (): Promise<string> => {
		if (linkImage === initialImage) return initialImage;
		const myFile = await blobUrlToFile(linkImage, 'selectedLinkImage.jpg');

		return new Promise((resolve, reject) => {
			addFile(myFile, (fileUrl) => {
				if (fileUrl) {
					notify.info('Imagine adăugată');
					resolve(fileUrl);
				} else {
					reject(new Error('Failed to upload image'));
				}
			});
		});
	};
	;

	const handleFormSubmit = async (data: IFormInput): Promise<void> => {
		try {
			// Upload image to the cloud
			const uploadedImageUrl = await handleSave();
			console.log('Uploaded image URL:', uploadedImageUrl);

			// Perform the backend call with form data and image URL
			const payload = { ...data, imageUrl: uploadedImageUrl };
			console.log('Submitting payload to backend:', payload);
			onSubmit(payload);

			// Reset the form after successful submission
			// reset();
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	};

	return (
		<form onSubmit={handleSubmit(handleFormSubmit)} >
			<Flex
				width="450px"
				height="480px"
				column
				p={2}
				sx={{ border: '1px solid #9e9e9e', borderRadius: '8px' }}
			>
				<Grid container spacing={2}>
					{/* Title Field */}
					<Grid item xs={12}>
						<Controller
							name="title"
							control={control}
							rules={{ required: 'Titlul este necesar' }}
							render={({ field }) => (
								<TextField
									{...field}
									label="Titlu"
									fullWidth
									size="small"
									error={!!errors.title}
									helperText={errors.title?.message}
								/>
							)}
						/>
					</Grid>

					{/* Description Field */}
					<Grid item xs={12}>
						<Controller
							name="description"
							control={control}
							rules={{
								required: 'Descrierea este necesară',
								maxLength: {
									value: 1500,
									message: 'Descrierea nu poate depăși 150 de caractere',
								},
							}}
							render={({ field }) => (
								<TextField
									{...field}
									label="Descriere"
									multiline
									minRows={3}
									maxRows={7}
									fullWidth
									size="small"
									error={!!errors.description}
									helperText={errors.description?.message}
									sx={{
										'& .MuiInputBase-root': {
											maxHeight: '270px',
											minHeight: '60px',
										},
									}}
								/>
							)}
						/>
					</Grid>

					{/* Image Upload Button */}
					<Grid item container xs={12} justifyContent="center">
						<ShareImageComponent
							cloudHandler={setLinkImage}
							initialImage={initialImage}
							loadingMedia={loadingMedia}
						/>
					</Grid>

					{/* Save Button */}
					<Grid item container xs={12} mt={1} justifyContent="center">
						<Button
							type="submit"
							variant="contained"
							disabled={!isValid} // Disable the button if form is not valid
						>
							Generează și salvează linkul
						</Button>
					</Grid>
				</Grid>
			</Flex>
		</form>
	);
};
