import React, { FC, useState } from 'react';
import { Box, Button, ButtonGroup, Card, Typography } from '@mui/material';
import { Flex } from '../flex';
import { DiscordLogo, FacebookLogo, InstagramLogo, LinkedinLogo, WhatsappLogo, XLogo } from '@/assets';
import { OGMetadata } from '@/hooks/fetch-hooks/use-pretty-links/types';

interface IPreviewShare {
	ogMeta: OGMetadata;
}

export const PreviewShare: FC<IPreviewShare> = ({ ogMeta }) => {
	const [selectedPlatform, setSelectedPlatform] = useState<
		'facebook' | 'instagram' | 'whatsapp' | 'x' | 'linkedin' | 'discord'
	>('facebook');

	const renderPreviewContent = (): React.ReactElement | null => {
		switch (selectedPlatform) {
			case 'facebook':
				return (
					<Flex width={'410px'} height={'530px'} column
						sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
						<Typography fontSize={22} mb={2}>Facebook</Typography>
						<Box component={'img'} alt='meta-cover' src={ogMeta?.ogImage} sx={{
							width: '410px',
							height: '215px',
							objectFit: 'cover',
							border: '2px solid #dadde1',
							borderBottom: 0
						}} />
						<Flex column sx={{
							width: '410px',
							backgroundColor: '#f2f3f5',
							padding: '12px 10px',
							alignItems: 'flex-start',
							gap: '3px',
							border: '2px solid #dadde1',
						}}>
							<Typography fontSize={'12px'} color={'#606770'} overflow={'hidden'}
								width={'100%'} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
								{ogMeta?.ogURL}
							</Typography>
							<Typography fontSize={'16px'} fontWeight={600} color={'#1d2129'} overflow={'hidden'}
								width={'100%'} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
								{ogMeta?.ogTitle}
							</Typography>
							<Typography fontSize={'14px'} color={'#606770'} overflow={'hidden'} width={'100%'}
								sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
								{ogMeta?.ogDescription}
							</Typography>
						</Flex>
					</Flex>
				);
			case 'whatsapp':
				return (
					<Flex width={'410px'} height={'530px'} column
						sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
						<Typography fontSize={22} mb={2}>Whatsapp</Typography>
						<Box component={'img'} alt='meta-cover' src={ogMeta?.ogImage} sx={{
							width: '400px',
							height: '215px',
							objectFit: 'cover',
							border: '1px solid #dadde1',
							borderBottom: 0,
							borderRadius: '8px 8px 0px 0px'
						}} />
						<Flex column sx={{
							width: '400px',
							backgroundColor: '#025143',
							padding: '12px 10px',
							alignItems: 'flex-start',
							gap: '3px',
							border: '1px solid #dadde1',
							borderTop: 0,
							borderRadius: '0px 0px 8px 8px'
						}}>
							<Typography fontSize={'16px'} fontWeight={600} color={'#e9edefe0'} overflow={'hidden'}
								width={'100%'}
								sx={{
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 2,
									textOverflow: 'ellipsis',
								}}>
								{ogMeta?.ogTitle}
							</Typography>
							<Typography fontSize={'14px'} color={'#e9edefe0'} overflow={'hidden'}
								width={'100%'}
								sx={{
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 2,
									textOverflow: 'ellipsis',
								}}>
								{ogMeta?.ogDescription}
							</Typography>
							<Typography fontSize={'12px'} color={'#e9edef4d'} overflow={'hidden'}
								width={'100%'} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
								{ogMeta?.ogURL}
							</Typography>
						</Flex>
					</Flex>
				);
			case 'instagram':
				return (
					<Flex width={'410px'} height={'530px'} column
						sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
						<Typography fontSize={22} mb={2}>Instagram</Typography>
						<Flex sx={{
							border: '1px solid #dadde1', borderRadius: '24px', overflow: 'hidden'
						}}>
							<Flex column sx={{
								width: '410px',
								backgroundColor: '#3797F0',
								padding: '12px 10px',
								alignItems: 'flex-start',
								gap: '3px',
							}}>
								<Typography fontSize={'15px'} color={'#e0f1ff'} overflow={'hidden'}
									width={'100%'}
									sx={{
										display: '-webkit-box',
										WebkitBoxOrient: 'vertical',
										WebkitLineClamp: 2,
										textOverflow: 'ellipsis',
									}}>
									{ogMeta?.ogURL}
								</Typography>
							</Flex>
							<Box component={'img'} alt='meta-cover' src={ogMeta?.ogImage} sx={{
								width: '410px',
								height: '215px',
								objectFit: 'cover',
							}} />
							<Flex column sx={{
								width: '410px',
								backgroundColor: '#36393f',
								padding: '12px 10px',
								alignItems: 'flex-start',
								gap: '3px',
							}}>
								<Typography fontSize={'14px'} fontWeight={600} color={'#f5f5f5'} overflow={'hidden'}
									width={'100%'}
									sx={{
										display: '-webkit-box',
										WebkitBoxOrient: 'vertical',
										WebkitLineClamp: 2,
										textOverflow: 'ellipsis',
									}}>
									{ogMeta?.ogTitle}
								</Typography>
							</Flex>
						</Flex>
					</Flex>
				);
			case 'x':
				return (
					<Flex width={'410px'} height={'530px'} column
						sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
						<Typography fontSize={22} mb={2}>X ( Twitter )</Typography>
						<Flex position={'relative'}>
							<Box component={'img'} alt='meta-cover' src={ogMeta?.ogImage} sx={{
								width: '410px',
								height: '215px',
								objectFit: 'cover',
								border: '2px solid #dadde1',
								borderRadius: 3
							}} />
							<Typography fontSize={'12px'} color={'#fff'} overflow={'hidden'}
								sx={{
									textOverflow: 'ellipsis', whiteSpace: 'nowrap', position: 'absolute',
									bottom: 8, left: 8, padding: '4px 8px', borderRadius: '8px',
									backgroundColor: '#919191', maxWidth: '96%'
								}}>
								{ogMeta?.ogURL}
							</Typography>
						</Flex>
					</Flex>
				);
			case 'linkedin':
				return (
					<Flex width={'410px'} height={'530px'} column
						sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
						<Typography fontSize={22} mb={2}>LinkedIn</Typography>
						<Card elevation={2} sx={{
							width: '100%',
							alignItems: 'flex-start',
							borderRadius: '2px'
						}}>
							<Box component={'img'} alt='meta-cover' src={ogMeta?.ogImage} sx={{
								width: '410px',
								height: '215px',
								objectFit: 'cover',
							}} />
							<Flex column sx={{
								width: '410px',
								padding: '12px 10px',
								alignItems: 'flex-start',
								gap: '3px',
							}}>
								<Typography
									fontSize={'16px'}
									fontWeight={600}
									color={'#000000e6'}
									overflow={'hidden'}
									width={'100%'}
									sx={{
										display: '-webkit-box',
										WebkitBoxOrient: 'vertical',
										WebkitLineClamp: 2,
										textOverflow: 'ellipsis',
									}}
								>
									{ogMeta?.ogTitle}
								</Typography>
								<Typography fontSize={'12px'} color={'#00000099'} overflow={'hidden'} width={'100%'}
									sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
									{ogMeta?.ogURL}
								</Typography>
							</Flex>
						</Card>
					</Flex>
				);
			case 'discord':
				return (
					<Flex width={'410px'} height={'550px'} column
						sx={{ justifyContent: 'flex-start' }}>
						<Typography fontSize={22} mb={2}>Discord</Typography>
						<Flex justifyCenter sx={{
							width: '100%',
							backgroundColor: '#36393f',
							color: 'white',
							padding: '10px',
							borderRadius: '8px',
							alignItems: 'center',
							gap: '10px',
						}}>
							<Flex column sx={{ width: '100%', alignItems: 'flex-start', px: 1 }}>
								<Typography fontSize={'16px'} fontWeight={600} color={'#00b0f4'}
									overflow={'hidden'}
									width={'100%'}
									sx={{
										display: '-webkit-box',
										WebkitBoxOrient: 'vertical',
										WebkitLineClamp: 2,
										textOverflow: 'ellipsis',
									}} >
									{ogMeta?.ogTitle}
								</Typography>
								<Typography fontSize={'14px'} color={'#dcddde'} overflow={'hidden'}
									width={'100%'}
									sx={{
										display: '-webkit-box',
										WebkitBoxOrient: 'vertical',
										WebkitLineClamp: 2,
										textOverflow: 'ellipsis',
									}}>
									{ogMeta?.ogDescription}
								</Typography>
							</Flex>
							<Box component={'img'} alt='meta-cover' src={ogMeta?.ogImage} sx={{
								width: '380px',
								maxHeight: '380px',
								objectFit: 'cover',
								borderRadius: '4px',
							}} />
						</Flex>
					</Flex>
				);
			default:
				return null;
		}
	};

	return (
		<Flex column gap={2} sx={{ justifyContent: 'flex-start' }}>
			<Typography>Preview</Typography>
			<ButtonGroup variant="outlined" aria-label="Media button group">
				<Button
					onClick={() => setSelectedPlatform('facebook')}
					sx={{ backgroundColor: selectedPlatform === 'facebook' ? 'rgba(25, 118, 210, 0.2)' : 'inherit' }}
				>
					<Box component={'img'} alt='facebook-logo' src={FacebookLogo}
						sx={{ width: '36px', height: 'auto' }} />
				</Button>
				<Button
					onClick={() => setSelectedPlatform('whatsapp')}
					sx={{ backgroundColor: selectedPlatform === 'whatsapp' ? 'rgba(25, 118, 210, 0.2)' : 'inherit' }}
				>
					<Box component={'img'} alt='whatsapp-logo' src={WhatsappLogo}
						sx={{ width: '36px', height: 'auto' }} />
				</Button>
				<Button
					onClick={() => setSelectedPlatform('instagram')}
					sx={{ backgroundColor: selectedPlatform === 'instagram' ? 'rgba(25, 118, 210, 0.2)' : 'inherit' }}
				>
					<Box component={'img'} alt='instagram-logo' src={InstagramLogo}
						sx={{ width: '36px', height: 'auto' }} />
				</Button>
				<Button
					onClick={() => setSelectedPlatform('x')}
					sx={{ backgroundColor: selectedPlatform === 'x' ? 'rgba(25, 118, 210, 0.2)' : 'inherit' }}
				>
					<Box component={'img'} alt='x-logo' src={XLogo} sx={{ width: '36px', height: 'auto' }} />
				</Button>
				<Button
					onClick={() => setSelectedPlatform('linkedin')}
					sx={{ backgroundColor: selectedPlatform === 'linkedin' ? 'rgba(25, 118, 210, 0.2)' : 'inherit' }}
				>
					<Box component={'img'} alt='linkedin-logo' src={LinkedinLogo}
						sx={{ width: '36px', height: 'auto' }} />
				</Button>
				<Button
					onClick={() => setSelectedPlatform('discord')}
					sx={{ backgroundColor: selectedPlatform === 'discord' ? 'rgba(25, 118, 210, 0.2)' : 'inherit' }}
				>
					<Box component={'img'} alt='discord-logo' src={DiscordLogo}
						sx={{ width: '36px', height: 'auto' }} />
				</Button>
			</ButtonGroup>
			{renderPreviewContent()}
		</Flex >
	);
};
