import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AuthContext } from './context';
import { AuthState, IUser, IUserCredentials } from './types';
import {
	get, notify, post, readFromLocalStorage, removeFromLocalStorage,
	saveToLocalStorage, useAppDispatch
} from '@/utils';
import { AUTH_USER_STORAGE_KEY } from './constants';
import { useSocketContext } from '../socket';
import { projectActions } from '@/store/project/slice';
import { utilsActions } from '@/store/utils/slice';

const initialState: IUser = {
	_id: '',
	firstName: '',
	lastName: '',
	email: '',
	phone: undefined,
	role: undefined,
	isLoggedIn: false,
};

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [user, setUser] = useState<IUser | undefined>(initialState);
	const [authState, setAuthState] = useState<AuthState>(AuthState.Loading);
	const { authenticateSocket, connectSocket, disconnectSocket, socket } = useSocketContext();
	const dispatch = useAppDispatch();

	useEffect(() => {
		try {
			setAuthState(AuthState.Loading);
			const userParsed = readFromLocalStorage<IUser>(AUTH_USER_STORAGE_KEY);

			if (userParsed) {
				setUser(userParsed);
				setAuthState(AuthState.SignedIn);
				connectSocket();
				// authenticateSocket();
			} else {
				setAuthState(AuthState.SignedOut);
				disconnectSocket();
			}
		} catch (e) {
			console.log(e);
		}
	}, [connectSocket, disconnectSocket]);

	const signIn = useCallback(async (credentials: IUserCredentials) => {
		try {
			setAuthState(AuthState.Loading);
			const response = await post<IUserCredentials, { user: IUser }>('api/auth/login', credentials);
			const userRes = response?.data?.user as IUser;
			if (userRes) {
				saveToLocalStorage(AUTH_USER_STORAGE_KEY, { ...userRes, isLoggedIn: true });
				setUser({ ...userRes, isLoggedIn: true });
				setAuthState(AuthState.SignedIn);
				connectSocket();
				// authenticateSocket();
				notify.success('Autentificat cu succes!');
			}
		} catch (e) {
			const error = e as unknown as any;
			if (error.isNetworkError) {
				setAuthState(AuthState.NetworkError);
				notify.error('Eroare conectiune');
			} else {
				setAuthState(AuthState.SignedOut);
				notify.error(error.response.data.message || 'Autentificarea a esuat!');
			}
		}
		return undefined;
		// eslint-disable-next-line
	}, [connectSocket]);

	const refreshAuthState = useCallback((parsedAuthState: AuthState) => {
		setAuthState(parsedAuthState);
		if (parsedAuthState === AuthState.SignedOut) {
			setUser(initialState);
			disconnectSocket();
			dispatch(projectActions.clearProjects());
		}
		// eslint-disable-next-line
	}, [disconnectSocket]);

	const signOut = useCallback(async () => {
		try {
			const response = await get('api/auth/logout');
			if (response.status === 200) {
				localStorage.removeItem('currentPath');
				setUser(initialState);
				removeFromLocalStorage(AUTH_USER_STORAGE_KEY);
				setAuthState(AuthState.SignedOut);
				disconnectSocket();
				notify.success('Delogat cu succes');
				dispatch(projectActions.clearProjects());
				dispatch(utilsActions.toggleMobileDrawer(false));
			}
		} catch (e) {
			notify.error('Delogarea a esuat!');
		}
		// eslint-disable-next-line
	}, [disconnectSocket]);

	useEffect(() => {
		if (!socket) return;

		console.log('auth prov connect if');
		socket.on('connect', () => {
			console.log('User connected!');
			user?.role !== 'fsp' && authenticateSocket();
		});

		socket.on('disconnect', () => {
			console.log('User disconnected!');
		});
		// eslint-disable-next-line
		return () => {
			socket.off('connect');
			socket.off('disconnect');
		};
		//  eslint-disable-next-line
	}, [socket]);

	// Memoize the context value
	const value = useMemo(
		() => ({
			user,
			signIn,
			signOut,
			refreshAuthState,
			authState,
			setUser
		}),
		[user, signIn, signOut, authState, refreshAuthState, setUser],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
