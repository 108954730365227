import { Logout } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useAuthContext } from '@/providers';

interface IDrawerFooter {
	isOpen: boolean;
}

export const DrawerFooter: FC<IDrawerFooter> = ({ isOpen }) => {
	const { signOut } = useAuthContext();

	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(769));

	return (
		<Box sx={{
			display: 'flex', justifyContent: isTabletOrSmaller ? 'center' : 'flex-start',
			borderTop: '1px solid #fff'
		}} pl={isOpen ? 1 : '10px'} pt={2} >
			<Button sx={{
				minWidth: isTabletOrSmaller ? 177 : 'auto'
			}} onClick={() => signOut()} >
				<Logout />
				{isOpen && <Typography >DELOGARE</Typography>}
			</Button>
		</Box>
	);
};