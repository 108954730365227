import { useCallback, useEffect, useState } from 'react';
import { ModalType, OGMetadata, PrettyLinksReturnType, SharedDocument } from './types';
import { notify, stateSetter } from '@/utils';
import { createPrettyLink } from './actions';

export function usePrettyLinks(
	requestId: string,
	type: ModalType,
	initiateRequest: boolean = false,
): PrettyLinksReturnType {
	const [data, setData] = useState<SharedDocument | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const getLink = useCallback(
		async (paramId: string, typeParam: ModalType) => {
			try {
				setLoading(true);
				const response = await createPrettyLink(paramId, typeParam);
				setData(response);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const updatePrettyLink = useCallback(
		async (paramId: string, typeParam: ModalType, meta: OGMetadata) => {
			try {
				setLoading(true);
				const response = await createPrettyLink(paramId, typeParam, meta);
				setData(response);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (initiateRequest && requestId && type) {
			getLink(requestId, type);
		}
		// eslint-disable-next-line
	}, [initiateRequest, requestId, type]);

	return {
		data: data || ({} as SharedDocument),
		setData: setData as stateSetter<SharedDocument>,
		loading,
		updatePrettyLink,
	};
}