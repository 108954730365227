import { FC, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { SimpleDrawerHeader } from './header';
import { BaseDrawer, DrawerContent } from './styled';

interface ISimpleDrawerProps {
	title: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	children?: ReactNode;
	hasSelectedRoomMobile?: boolean;
}

export const SimpleDrawer: FC<ISimpleDrawerProps> = ({
	title, open, setOpen, hasSelectedRoomMobile, children
}) => {
	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(550));
	const closeDrawer = (): void => {
		setOpen((prev: boolean) => !prev);
	};
	console.log('simple drdawer param', hasSelectedRoomMobile);
	return (
		<BaseDrawer variant="permanent" open={open}
			sx={{ display: isTabletOrSmaller && hasSelectedRoomMobile ? 'none!important' : 'flex' }}>
			<SimpleDrawerHeader title={title} open={open} closeDrawer={closeDrawer} />
			<DrawerContent>
				{children}
			</DrawerContent>
		</BaseDrawer>
	);
};

SimpleDrawer.defaultProps = {
	children: undefined,
	hasSelectedRoomMobile: false
};