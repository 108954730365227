/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IUtilsSlice } from './types';

const initialState: IUtilsSlice = {
	isDrawerOpen: true,
	isMobileDrawerOpen: false,
	isComplexFilterOpen: false,
	isSimpleFilterOpen: false,
};

const utilsSlice = createSlice({
	name: 'utils',
	initialState,
	reducers: {
		toggleDrawer: (state, action) => {
			state.isDrawerOpen = action.payload;
		},
		toggleMobileDrawer: (state, action) => {
			state.isMobileDrawerOpen = action.payload;
		},
		toggleComplexFilter: (state) => {
			state.isComplexFilterOpen = !state.isComplexFilterOpen;
		},
		toggleSimpleFilter: (state) => {
			state.isSimpleFilterOpen = !state.isSimpleFilterOpen;
		},
	},
});

export const utilsReducer = utilsSlice.reducer;
export const utilsActions = utilsSlice.actions;
