import { AxiosError } from 'axios';
import { put } from '@/utils/axios';
import { ModalType, OGMetadata, SharedDocument } from './types';

async function createPrettyLink(
	id: string,
	type: ModalType,
	meta?: OGMetadata
): Promise<SharedDocument> {
	try {
		const payload = {
			id,
			type,
			...(meta ? { meta } : {}),
		};

		const res = await put('share', { ...payload });
		const data = res.data as unknown as { sharedDocument: SharedDocument };
		console.log('pretty links request', data);
		return data?.sharedDocument || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

export { createPrettyLink };