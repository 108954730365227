import { FC, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { SimpleDrawer } from '../simple-drawer';
import { LayoutBase } from './styled';

interface IDrawerSideContentProps {
	siderChildren?: ReactNode;
	children?: ReactNode;
	drawerTitle: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	hasSelectedRoomMobile?: boolean;
}

export const DrawerSideContent: FC<IDrawerSideContentProps> = ({
	drawerTitle,
	children,
	siderChildren,
	open,
	setOpen,
	hasSelectedRoomMobile
}) => {
	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(550));

	return (
		<>
			<SimpleDrawer title={drawerTitle} open={open} setOpen={setOpen}
				hasSelectedRoomMobile={hasSelectedRoomMobile}>
				{siderChildren}
			</SimpleDrawer>
			<LayoutBase display={isTabletOrSmaller ? (hasSelectedRoomMobile ? 'flex' : 'none!important') : 'flex'}
				isDrawerOpen={open}>
				{children}
			</LayoutBase>
		</>
	);
};

DrawerSideContent.defaultProps = {
	children: undefined,
	siderChildren: undefined,
	hasSelectedRoomMobile: false
};