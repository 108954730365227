import { ContactsOutlined } from '@mui/icons-material';
import { Avatar, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '@/providers';
import { HeaderRightSide, HeaderRoleButton, HeaderUserName, StyledBadge } from '../styled';
import { SessionExpireTimer } from './SessionExpireTimer';

export const UserSide: FC = () => {
	const { user } = useAuthContext();
	const userName = `${user?.firstName} ${user?.lastName}`;
	const navigate = useNavigate();

	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(769));
	const ismobileOrSmaller = useMediaQuery(theme.breakpoints.down(500));

	return (
		<HeaderRightSide>

			<SessionExpireTimer />

			{user?.role === 'moderator' && !isTabletOrSmaller && <ContactsOutlined />}
			{!isTabletOrSmaller &&
				<StyledBadge
					onClick={() => navigate('/common/profile')}
					overlap="circular"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					variant="dot"
					sx={{ cursor: 'pointer' }}
				>
					<Avatar alt={userName} src={user?.avatar || ''} />
				</StyledBadge>
			}
			<HeaderUserName>{userName.toUpperCase()}</HeaderUserName>

			{!ismobileOrSmaller &&
				<HeaderRoleButton size="small">
					{user?.role?.toUpperCase()}
				</HeaderRoleButton>
			}

		</HeaderRightSide>
	);
};