import React, { FC, Fragment, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { utilsActions } from '@/store/utils/slice';
import { useAppDispatch, useAppSelector, uuid } from '@/utils';
import SuperadminMenu from './data/superadmin-menu.json';
import AdminMenu from './data/admin-menu.json';
import FspMenu from './data/fsp-menu.json';
import ModeratorMenu from './data/moderator-menu.json';
import { DrawerFooter } from './drawer-footer';
import { DrawerHeader } from './drawer-header';
import { MenuTabs } from './menu-items';
import { Drawer, DrawerMobile, MenuWrapper } from './styled';
import { useAuthContext, UserRole } from '@/providers';

export interface MenuItem {
	type?: string;
	title: string;
	icon: string;
	url: string;
	children?: MenuItem[];
}

// eslint-disable-next-line
const getMenuByUserRole = (role: UserRole) => {
	switch (role) {
		case 'superadmin':
			return SuperadminMenu;
		case 'admin':
			return AdminMenu;
		case 'moderator':
			return ModeratorMenu;
		case 'fsp':
			return FspMenu;
		default:
			return undefined;
	};
};

export const SideDrawer: FC = () => {
	const { isDrawerOpen, isMobileDrawerOpen } = useAppSelector((state) => state.utils);
	const { user } = useAuthContext();
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(769));
	const menuItems = useMemo(() => getMenuByUserRole(user?.role), [user?.role]);

	const renderElements = (closeAction: () => void): React.ReactElement => (
		<>
			<DrawerHeader
				isHovered
				open={isDrawerOpen}
				closeDrawer={closeAction}
			/>
			{/* ****************| MENU ACTIONS |************************* */}
			<MenuWrapper>
				{menuItems && menuItems.map(
					(el) =>
						!!el.children?.length && (
							<Fragment key={`menu-tabs-${uuid()}`}>
								<MenuTabs menuItems={el?.children || []} />
							</Fragment>
						),
				)}
			</MenuWrapper>
			<DrawerFooter isOpen={isDrawerOpen} />
		</>
	);

	return isTabletOrSmaller ? (
		<DrawerMobile variant="permanent" open={isMobileDrawerOpen}
			onClose={(): { payload: undefined; type: 'utils/toggleMobileDrawer' } =>
				dispatch(utilsActions.toggleMobileDrawer(!isMobileDrawerOpen))
			}
			sx={{ backgroundColor: 'red' }}
		>
			{renderElements((): { payload: undefined; type: 'utils/toggleMobileDrawer' } =>
				dispatch(utilsActions.toggleMobileDrawer(!isMobileDrawerOpen))
			)}
		</DrawerMobile>
	) : (
		<Drawer variant="permanent" open={isDrawerOpen} sx={{ display: isTabletOrSmaller ? 'none' : 'inherit' }} >
			{renderElements((): { payload: undefined; type: 'utils/toggleDrawer' } =>
				dispatch(utilsActions.toggleDrawer(!isDrawerOpen))
			)}
		</Drawer>
	);
};
