import {
	Box,
	BoxProps,
	CSSObject,
	DividerProps,
	DrawerProps,
	Divider as MuiDivider,
	Drawer as MuiDrawer,
	Theme,
	styled
} from '@mui/material';
import { FC } from 'react';
import { drawerTheme } from './drawerTheme';

export const drawerWidth = '200px';

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	width: `calc(${theme.spacing(10)} + 2px)`,
});

const Drawer: FC<DrawerProps> = styled(MuiDrawer, { shouldForwardProp: (prop: string) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		'.MuiPaper-root': {
			margin: theme.spacing(4, 2, 2, 4),
			backgroundColor: drawerTheme.background,
			color: theme.palette.common.white,
			height: 'calc(100% - 46px)',
			borderRadius: '16px',
			padding: theme.spacing(3, 0, 2, 0),
			overflow: 'visible',
			'.MuiSvgIcon-root': {
				color: theme.palette.common.white
			},
			'.MuiTypography-root': {
				color: theme.palette.common.white,
				fontWeight: 600,
				fontSize: '14px',
				lineHeight: '24px',
				marginLeft: theme.spacing(1),
			},
			'.MuiButtonBase-root': {
				height: '36px',
			},
			'.MuiListItemIcon-root': {
				margin: open ? 'inherit' : '0px auto'
			}
		},
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

const DrawerMobile: FC<DrawerProps> = styled(MuiDrawer, { shouldForwardProp: (prop: string) => prop !== 'open' })(
	({ theme, open }) => ({
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		'.MuiPaper-root': {
			backgroundColor: drawerTheme.background,
			color: theme.palette.common.white,
			height: '100%',
			width: open ? '100%!important' : '0px!important',
			padding: theme.spacing(3, 0, 2, 0),
			overflow: 'visible',
			'.MuiSvgIcon-root': {
				color: theme.palette.common.white
			},
			'.MuiTypography-root': {
				color: theme.palette.common.white,
				fontWeight: 600,
				fontSize: '14px',
				lineHeight: '24px',
				marginLeft: theme.spacing(1),
			},
			'.MuiButtonBase-root': {
				height: '36px',
			},
			'.MuiListItemIcon-root': {
				margin: open ? 'inherit' : '0px auto'
			}
		},
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			display: 'none',
		}),
		'& .MuiDrawer-paper': {
			zIndex: 9999,
		},
		width: open ? '100%!important' : '0px!important',
	}),
);

const MenuWrapper: FC<BoxProps> = styled(Box)({
	overflowY: 'auto',
	height: 'calc(100% - 120px)',
	// Media query for tablet devices
	'@media (max-width: 768px)': {
		display: 'flex',
		justifyContent: 'center'
	},
});

const MenuDivider: FC<DividerProps> = styled(MuiDivider)(({ theme }) => ({
	border: `2px solid ${theme.palette.primary.dark}`,
	margin: '8px 0px',
	width: '100%',
}));

export { Drawer, MenuDivider, MenuWrapper, DrawerMobile };

