import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { Flex } from '../flex';
import { stateSetter } from '@/utils';

export async function blobUrlToFile(blobUrl: string, fileName: string): Promise<File> {
	const response = await fetch(blobUrl);
	const blob = await response.blob();
	return new File([blob], fileName, { type: blob.type });
}

interface IShareImageComponent {
	cloudHandler: stateSetter<string>;
	loadingMedia: boolean;
	labelParam?: string;
	initialImage: string;
}

export const ShareImageComponent: FC<IShareImageComponent> = ({
	cloudHandler, labelParam = 'imagine', initialImage, loadingMedia
}) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [image, setImage] = useState<string>(initialImage || '');
	const [displayImage, setDisplayImage] = useState<string>('');

	const handleButtonClick = useCallback(() => {
		fileInputRef.current?.click();
	}, []);

	const handleClear = (): void => {
		setImage(initialImage);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const fileUrl = URL.createObjectURL(file);
			setImage(fileUrl);
		}
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	useEffect(() => {
		if (image?.length > 0) {
			cloudHandler(image);
			setDisplayImage(image);
		} else {
			cloudHandler(initialImage);
		}
		// eslint-disable-next-line
	}, [image]);

	return (
		<Flex width={'100%'} column >
			<Flex width={'100%'} gap={2} sx={{ position: 'relative', alignItems: 'flex-start' }} >
				<Box component={'img'} src={displayImage} alt={'link-shareImage'}
					sx={{
						width: '120px', height: '110px', border: '1px solid #9e9e9e',
						objectFit: 'contain'
					}} />
				<Flex column sx={{ alignItems: 'flex-start' }}>
					<Box>
						<input
							type="file"
							accept=".png, .jpg, .jpeg, .webp"
							onChange={handleFileChange}
							ref={fileInputRef}
							style={{ display: 'none' }}
						/>
						<Button variant='text' onClick={handleButtonClick}>
							{/* eslint-disable-next-line */}
							{`Schimbă ${labelParam}`}
						</Button>
					</Box>
					{image !== initialImage &&
						<Button variant='text' onClick={handleClear}>
							{`Resetează ${labelParam}`}
						</Button>
					}
					{loadingMedia &&
						<Flex justifyCenter sx={{
							position: 'absolute', left: 0, top: 0, width: '120px', height: '110px',
							backgroundColor: 'rgba(255, 255, 255, 0.4)'
						}}>
							<CircularProgress />
						</Flex>
					}
				</Flex>
			</Flex>
		</Flex>
	);
};

ShareImageComponent.defaultProps = {
	labelParam: 'imagine'
};