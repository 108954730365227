import { FC, useState } from 'react';
import { ContentCopy, ShareOutlined } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { SharedDocument, usePrettyLinks } from '@/hooks';
import { Flex } from '../flex';
import { SimpleDialogTransition } from '../simple-dialog-transition';
import { ShareForm } from './ShareForm';
import { notify } from '@/utils';
import { PreviewShare } from './PreviewShare';

type ModalType = 'projects' | 'surveys' | 'announcements';

interface IShareModal {
	type: ModalType;
	requestId: string;
}

interface IFormInput {
	title: string;
	description: string;
	displayImage: string;
}

interface IForm {
	handleFormSubmit: (formData: {
		title: string;
		description: string;
		imageUrl?: string;
	}) => Promise<void>;
	data: SharedDocument
}

const Form: FC<IForm> = ({ handleFormSubmit, data }) => {
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		watch,
		setValue
	} = useForm<IFormInput>({
		defaultValues: {
			title: data?.meta?.ogTitle,
			description: data?.meta?.ogDescription,
			displayImage: data?.meta?.ogImage,
		},
		mode: 'onChange',
	});

	const formValues = watch();

	const handleCopy = async (): Promise<void> => {
		try {
			await navigator.clipboard.writeText(data?.url);
			notify.success('Link-ul a fost copiat in clipboard');
		} catch (err) {
			console.error('Failed to copy text: ', err);
		}
	};

	return (
		<>
			<Flex column gap={2}>
				<Typography>Editează detaliile</Typography>

				<ShareForm
					initialImage={data?.meta?.ogImage}
					onSubmit={handleFormSubmit}
					control={control}
					errors={errors}
					handleSubmit={handleSubmit}
					isValid={isValid}
					setValue={setValue}
				/>

				<Flex width={'100%'} justifyCenter gap={2} >
					<TextField
						value={data?.url}
						disabled
						fullWidth
						variant="outlined"
						size="small"
						InputProps={{
							readOnly: true,
						}}
					/>
					<Button
						variant="outlined"
						color="primary"
						onClick={handleCopy}
						startIcon={<ContentCopy />}
					>
						Copiază linkul
					</Button>
				</Flex>
			</Flex>

			<PreviewShare ogMeta={{
				...data?.meta,
				ogTitle: formValues?.title,
				ogDescription: formValues?.description,
				ogImage: formValues?.displayImage,
			}} />
		</>

	);
};

export const ShareModal: FC<IShareModal> = ({ type, requestId }) => {
	const generateTitle = (): string => {
		switch (type) {
			case 'projects':
				return 'Distribuie proiect';
			case 'surveys':
				return 'Distribuie sondaj';
			case 'announcements':
				return 'Distribuie anunț';
			default:
				return 'Distribuie';
		}
	};

	const [open, setOpen] = useState(false);

	const { data, loading, updatePrettyLink } = usePrettyLinks(requestId, type, open);

	console.log('pretty link page', data);

	const handleClickOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleFormSubmit = async (formData: {
		title: string; description: string, imageUrl?: string
	}): Promise<void> => {
		console.log(formData);
		// Handle form submission logic here
		try {
			await updatePrettyLink(requestId, type, {
				...data?.meta,
				ogTitle: formData.title,
				ogDescription: formData.description,
				ogImage: formData.imageUrl || data?.meta?.ogImage,
			});
			notify.success('Detaliile au fost actualizate cu succes');
		} catch (err) {
			console.error('Failed to update pretty link: ', err);
			notify.error('A apărut o eroare in timpul actualizării detaliilor');
		}
	};

	return (
		<>
			<Tooltip title='Distribuie'>
				<span>
					<IconButton onClick={handleClickOpen} sx={{ width: 'auto' }}>
						<ShareOutlined />
					</IconButton>
				</span>
			</Tooltip>
			<SimpleDialogTransition open={open} setOpen={setOpen} title={generateTitle()}
				fastClose customClose={handleClose} maxWidth='100%'
			>
				<Flex width={'900px'} sx={{ justifyContent: 'space-between' }}>
					{loading ?
						<Flex width={'100%'} height={'750px'} justifyCenter>
							<CircularProgress />
						</Flex>
						:
						<Form handleFormSubmit={handleFormSubmit} data={data} />
					}
				</Flex>
			</SimpleDialogTransition>
		</>
	);
};
