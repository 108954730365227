import { AxiosRequestConfig, AxiosResponse } from 'axios';
import request from './config';
import { CITIZEN_API_URL } from './constants';

export const get = (
	endpoint: string,
	cancelToken?: AxiosRequestConfig['cancelToken'],
): Promise<AxiosResponse<any, any>> =>
	request.get(`${CITIZEN_API_URL}/${endpoint}`, {
		cancelToken
	});
export const post = <req, res>(endpoint: string, data?: req): Promise<AxiosResponse<any, any>> =>
	request.post<res>(`${CITIZEN_API_URL}/${endpoint}`, data);
export const edit = <T>(endpoint: string, data?: T): Promise<AxiosResponse<any, any>> =>
	request.patch<T>(`${CITIZEN_API_URL}/${endpoint}`, data);
export const del = (endpoint: string): Promise<AxiosResponse<any, any>> =>
	request.delete(`${CITIZEN_API_URL}/${endpoint}`);
export const put = <T>(endpoint: string, data?: T): Promise<AxiosResponse<any, any>> =>
	request.put<T>(`${CITIZEN_API_URL}/${endpoint}`, data);

