import { ChevronLeft, CloseOutlined } from '@mui/icons-material';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { WhiteLogo } from '@/assets';
import { ToggleButtonContainer } from './styled';
import { Flex } from '@/components/flex';

const Wrapper: FC<{ children: ReactNode }> = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'space-between',
	justifyContent: 'center',
	position: 'relative',
	padding: theme.spacing(0, 1),
	width: '100%',
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	height: '86px',
	'@media (max-width: 768px)': {
		justifyContent: 'center'
	},
}));

interface IProps {
	open: boolean;
	closeDrawer: () => void;
	isHovered: boolean;
}

export const DrawerHeader: FC<IProps> = ({ open, closeDrawer, isHovered }) => {
	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(769));

	return (
		<Wrapper>
			<Box component={'img'} alt='white-logo' src={WhiteLogo} sx={{
				width: open ? '69px' : '56px',
				height: 'auto',
				maxHeight: '56px',
				maxWidth: '69px',
			}} />
			{!isTabletOrSmaller ?
				<ToggleButtonContainer isHovered={isHovered}>
					<ChevronLeft
						onClick={closeDrawer} aria-label="toggle" aria-labelledby="toggle"
						sx={{
							transform: `rotate(${open ? 0 : 180}deg)`,
							transition: 'all 0.3s ease-in',
						}}
					/>
				</ToggleButtonContainer>
				:
				<Flex sx={{ position: 'absolute', right: 8, top: -8 }}>
					<CloseOutlined sx={{ width: 36, height: 36, color: '#fff' }} onClick={closeDrawer} />
				</Flex>
			}
		</Wrapper>
	);
};