import { ChevronLeft } from '@mui/icons-material';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { Flex } from '../../flex';
import { DrawerTitle } from './styled';

interface IProps {
	open: boolean;
	closeDrawer: () => void;
	title: string;
}

export const SimpleDrawerHeader: FC<IProps> = ({ open, closeDrawer, title }) => {
	const theme = useTheme();
	const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down(550));

	return (
		<Flex sx={{ justifyContent: 'space-between', margin: '8px 0 8px 8px' }}>
			<DrawerTitle>{title}</DrawerTitle>
			{!isTabletOrSmaller &&
				<IconButton onClick={closeDrawer} aria-label="toggle" aria-labelledby="toggle">
					<ChevronLeft
						fontSize="medium"
						sx={{
							transform: `rotate(${open ? 0 : 180}deg)`,
							transition: 'all 0.3s ease-in',
						}}
					/>
				</IconButton>
			}
		</Flex>
	);
};
